import React, { useEffect } from "react";
import classes from "./Heading.module.scss";
import { useTranslation } from "react-i18next";
import Checkmarks from "./Checkmarks.tsx";
import { useDispatch, useSelector } from "react-redux";
import { translationLoaded } from "../../store/loadingSlice.js";

const Heading = () => {
  const { t } = useTranslation();

  const headingMobilePieces: string[] = t("heading-mobile", {
    returnObjects: true,
  });
  const dispatch = useDispatch();

  if (typeof headingMobilePieces === "object") {
    dispatch(translationLoaded("heading"));
  }

  return (
    <>
      <div className={`${classes.heading} ${classes["heading-desktop"]}`}>
        <div className={classes["heading__row--1"]}>
          {t("heading.1")}
          <Checkmarks />
        </div>
        <span className={classes["heading__row--2"]}>{t("heading.2")}</span>
        <span className={classes["heading__row--3"]}>
          {t("heading.3")}
          <span className={classes["text-blue"]}> {t("heading.4")}</span>
        </span>
      </div>

      <div className={`${classes.heading} ${classes["heading-mobile"]}`}>
        {typeof headingMobilePieces === "object" &&
          headingMobilePieces.map((text, index) => {
            if (index < headingMobilePieces.length - 1)
              return (
                <span className={classes[`heading__row--${index}`]} key={text}>
                  {text}
                </span>
              );
          })}
        <span
          className={`${classes["text-blue"]} ${classes["heading__row--4"]}`}
        >
          {headingMobilePieces[headingMobilePieces.length - 1]}
        </span>
        <Checkmarks />
      </div>
    </>
  );
};

export default Heading;
