import React, { useEffect } from "react";
import classes from "./FormCard.module.scss";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { translationLoaded } from "../../store/loadingSlice";
import arrowRightIcon from "../../media/icons/arrowRight.svg";

const CardText = () => {
  const { t } = useTranslation();
  const cardHeaderPieces: string[] = t("card-text.header", {
    returnObjects: true,
  });

  const dispatch = useDispatch();
  if (typeof cardHeaderPieces === "object") {
    dispatch(translationLoaded("cardText"));
  }

  return (
    <div className={classes["card-text-container"]}>
      <div className={classes["card-text-container__heading"]}>
        {typeof cardHeaderPieces === "object" &&
          cardHeaderPieces.map((text, index) => {
            if (index === cardHeaderPieces.length - 1)
              return (
                <div key={text}>
                  {text} <img src={arrowRightIcon} alt="Arrow to right icon" />
                </div>
              );
            return <div>{text}</div>;
          })}
      </div>
      <p className={classes["card-text-container__body"]}>
        {t("card-text.body")}
      </p>
      <p className={classes["card-text-container__email"]}>info@go-te.co</p>
    </div>
  );
};

export default CardText;
