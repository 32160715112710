import React, { useEffect } from "react";
import classes from "./AboutUs.module.scss";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { translationLoaded } from "../../store/loadingSlice";

interface aboutUsItem {
  header: string[];
  body: string;
}

const AboutUs = () => {
  const { t } = useTranslation();
  const aboutUsBlocks: aboutUsItem[] = t("about-us.information", {
    returnObjects: true,
  });
  const dispatch = useDispatch();
  if (typeof aboutUsBlocks === "object") {
    dispatch(translationLoaded("aboutUs"));
  }
  return (
    <div className={classes["about-us-container"]} id="about-us">
      <h1 className={classes["heading-primary"]}>{t("about-us.header")}</h1>

      <div className={classes["blocks-container"]}>
        {typeof aboutUsBlocks === "object" &&
          aboutUsBlocks.map((item, index) => {
            return (
              <div
                className={`${classes.block} ${classes[`block--${index + 1}`]}`}
                key={item.body}
              >
                <div className={classes["block-text"]}>
                  <div className={classes["header-container"]}>
                    {typeof item.header === "object" &&
                      item.header.map((header) => (
                        <h2 className={classes["block-header"]} key={header}>
                          {header}
                        </h2>
                      ))}
                  </div>
                  <div className={classes["block-body"]}>{item.body}</div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default AboutUs;
