import "./App.scss";
import Navigation from "./components/navigation/Navigation.tsx";
import Heading from "./components/heading/Heading.tsx";
import AboutUs from "./components/aboutUs/AboutUs.tsx";
import FormCard from "./components/formCard/FormCard.tsx";
import Footer from "./components/footer/Footer.tsx";
import noise from "./media/noise.png";
import { useEffect, useState } from "react";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import eng from "./trans/eng.json";
import { useSelector } from "react-redux";

function App() {
  localStorage.setItem("language", JSON.stringify("eng"));
  const [allTransitionsWereLoaded, setAllTransitionsWereLoaded] =
    useState(false);

  const translationLoaded = useSelector(
    (state) => state.loading.translationIsLoaded
  );

  useEffect(() => {
    if (!Object.values(translationLoaded).includes(false)) {
      setAllTransitionsWereLoaded(true);
    }
    console.log("translationLoaded ", translationLoaded);
  }, [translationLoaded]);

  return (
    <>
      <div className="App">
        <section className="section--1">
          <div className="circle circle--4"></div>
          <div className="circle circle--3"></div>
          <div className="circle circle--2"></div>
          <div className="circle circle--1"></div>
          <img src={noise} className="noise" />
          <Navigation />
          <Heading />
        </section>

        <section className="section--2">
          <AboutUs />
          <FormCard />
        </section>
        <Footer />
      </div>
    </>
  );
}

export default App;
